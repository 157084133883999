import React, { lazy, Suspense } from 'react';
import Whatsapp from "./modules/components/Whatsapp";
import ProductHero from "./modules/views/ProductHero";
import withRoot from "./modules/withRoot";
import RenderIfVisible from "react-render-if-visible";

const ProductHowItWorks = lazy(() => import('./modules/views/ProductHowItWorks'));
const ProductValues = lazy(() => import('./modules/views/ProductValues'));
const ProductSmokingHero = lazy(() => import('./modules/views/ProductSmokingHero'));
const Instagram = lazy(() => import('./modules/views/Instagram'));
const AppFooter = lazy(() => import('./modules/views/AppFooter'));

function Index() {
  return (
    <React.Fragment>
      <ProductHero />
      <Whatsapp />
      <Suspense fallback={<div>Loading...</div>}>
        <RenderIfVisible defaultHeight={1309}><ProductHowItWorks /></RenderIfVisible>
        <RenderIfVisible defaultHeight={1268}><ProductValues /></RenderIfVisible>
        {/* <RenderIfVisible defaultHeight={600}><ProductCategories /></RenderIfVisible> */}
        <RenderIfVisible defaultHeight={523}><ProductSmokingHero /></RenderIfVisible>
        <RenderIfVisible defaultHeight={968}><Instagram /></RenderIfVisible>
        <RenderIfVisible defaultHeight={253}><AppFooter /></RenderIfVisible>
      </Suspense>
    </React.Fragment>
  );
}

export default withRoot(Index);
