import * as React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';

const StyledParallaxBanner = styled(ParallaxBanner)`
  height: 101vh; /* Default height */
  position: relative;

  @media (max-width: 600px) {
    height: 90vh; /* Adjusted height for mobile devices */
  }
`;

const ProductHeroLayoutRoot = styled('section')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Centers content vertically and horizontally
  height: '101vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '@media (max-width: 600px)': { // Applies to screens smaller than 600px wide
    height: '90vh', // Adjusted height for mobile devices
  }
}));

const ArrowDownContainer = styled(Box)({
  position: 'absolute',
  bottom: 75, // Positions the arrow at the bottom
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%', // Ensures the arrow is horizontally centered
  zIndex: 2, // Ensures the arrow is above other elements
});

interface ProductHeroLayoutProps {
  children: React.ReactNode;
  bgImage: string;
}

export default function ProductHeroLayout({ children, bgImage }: ProductHeroLayoutProps) {
  return (
    <StyledParallaxBanner
      layers={[{ image: bgImage, speed: -20 }]}
    >
      <ProductHeroLayoutRoot>
        <Container sx={{ zIndex: 2, position: 'relative' }}> {/* Ensures the content is above the dark overlay */}
          {children}
        </Container>
        <ArrowDownContainer>
          <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4V20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 13L12 20L19 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </ArrowDownContainer>
      </ProductHeroLayoutRoot>
    </StyledParallaxBanner>
  );
}
