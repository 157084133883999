import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const Whatsapp = () => {
  const balloonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: '9999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    backgroundColor: 'green',
    borderRadius: '50%',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
    transition: 'background-color 0.3s ease',
  };

  const iconStyle = {
    color: 'white',
    fontSize: '30px',
  };

  const handleHover = () => {
    const balloonElement = document.getElementById('whatsapp-balloon');
    balloonElement.style.backgroundColor = 'darkgreen';
  };

  const handleHoverExit = () => {
    const balloonElement = document.getElementById('whatsapp-balloon');
    balloonElement.style.backgroundColor = 'green';
  };

  return (
    <a
      href="https://wa.me/5511930978010?text=Gostaria+de+saber+mais+sobre+Psicoterapia"
      id="whatsapp-balloon"
      style={balloonStyle}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverExit}
    >
      <FaWhatsapp style={iconStyle} />
    </a>
  );
};

export default Whatsapp;
